
import { h, defineComponent, ref, onMounted, computed, watch, watchEffect, getCurrentInstance, onBeforeMount } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import PolicyService from "@/core/services/car/PolicyService";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { ElDivider } from "element-plus";
import { ElNotification } from "element-plus";
import { notif, roles } from '@/store/stateless/store';
import moment from "moment";
import router from "@/router";
import MasterService from '@/core/services/car/MasterService';
import { Mutations } from "@/store/enums/StoreEnums";
import QuotesService from "@/core/services/car/QuotesService";

interface PolicyBodyParam {
  policy_id: any;
}

export interface Document {
  document_id: any;
  type: any;
}

export interface CreditDebitDetail {
  invoice_id: any;
  credit_amount: any;
  credit_notes_no: any;
  debit_amount: any;
  debit_notes_no: any;
  endorsement_no: any;
  payment_date: any;
  reference_no: any;
}

interface UpdateDataBodyParam {
  policy_id: any;
  policy_start_date: any;
  policy_end_date: any;
  emirates_id: any;
  chassis_number: any;
  nationality: any;
  dob: any;
  credit_debit_details: CreditDebitDetail[];
  type: any;
  cancellation_debit_amount: any | null;
  cancellation_debit_notes_no: any | null;
  cancellation_credit_amount: any | null;
  cancellation_credit_notes_no: any | null;
  cancellation_endorsement_no: any | null;
  cancellation_payable_amount: any | null;
  cancellation_date: any | null;
}


interface UploadPolicyBodyParam {
  policy_id: any;
  policy_number: any;
  chassis_number: any;
  customer_name: any;
  policy_start_date: any;
  policy_end_date: any;
  insurance_payment: any;
  documents: Document[];
  credit_debit_details: CreditDebitDetail[];
  emirates_id:any;
  entity_type:any;
  is_discrepancy: boolean
  is_amended: number,
  is_vintage: number
  car_year: number,
  status: number
}
interface DownloadDocBodyParam{
    policy: Array<any>;
    temp:  Array<any>;
}
export default defineComponent({
  name: "update-data",

  data() {
    return {
      size: 10,
      spacer: h(ElDivider, { direction: "vertical" }),
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const inst = getCurrentInstance()
    
    const customerId = ref("");
    const leadId = ref("");
    let data = new FormData();
    let policyBodyParam = {} as PolicyBodyParam;
    let policyEndDate = ref("");
    let tempDocs = ref<any>([]);
    // let policyDocuments = ref<any>(null);
    let uploadPolicyBodyParam = {} as UploadPolicyBodyParam;


    let updateDataBodyParam = {} as UpdateDataBodyParam;


    
    let downloadDocBodyParam = {} as DownloadDocBodyParam;
    policyBodyParam.policy_id = route.params.policyId;
    uploadPolicyBodyParam.policy_id = policyBodyParam.policy_id;
    const requiredDocument = [1,2,3,4,5,6,7,8,9]
    const requiredDocumentNC = [1,2,3,4,5,6,7,8,9,21]
    let nationality = ref('');
    let dob = ref('');

    let editPolicyObj = computed(() => {
      const  data =  store.getters.getpolicyDetails; 
      
      // if(data && data.car_lead_invoices_edit_policy) {
      //   data.car_lead_invoices_edit_policy.map(x => {
      //     if(x.is_policy_saved === 0 && x.debit_amount === "0.00" && ["2", "4", "5"].includes(x.amend_type)) {
      //       x.debit_amount = x.amount
      //     }
      //   })
      // }

      
      return data
    });


    updateDataBodyParam = {
      policy_id: policyBodyParam.policy_id,
      policy_start_date: "",
      policy_end_date: "",
      emirates_id: "",
      chassis_number: "",
      nationality: "",
      dob: "",
      credit_debit_details: [],
      type:"",
      cancellation_debit_amount: null,
      cancellation_debit_notes_no: null,
      cancellation_credit_amount: null,
      cancellation_credit_notes_no: null,
      cancellation_endorsement_no: null,
      cancellation_payable_amount:null,
      cancellation_date:null,
    };
    uploadPolicyBodyParam = {
      policy_id: policyBodyParam.policy_id,
      policy_number: "",
      chassis_number: "",
      customer_name: "",
      policy_start_date: "",
      policy_end_date: "",
      insurance_payment: "",
      // credit_notes: "",
      // debit_notes: "",
      documents: [],
      credit_debit_details: [],
      emirates_id: "",
      entity_type:"",
      is_discrepancy: false,
      is_amended: 0,
      is_vintage: 0,
      car_year: 0,
      status: 0
    };
    let isFormValid = ref<boolean>(true);
    const rules = ref({
      cancellation_payable_amount: {
        message: "Please enter payable amount",
        show: false,
      },
      policy_no: {
        show: false,
        message: "Please enter policy number",
      },

      chassis: {
        show: false,
        message: "Chassis number",
      },
      customer_name: {
        show: false,
        message: "Customer name on policy",
      },
      policy_start_date: {
        show: false,
        message: "Please enter policy start date",
      },
      policy_end_date: {
        show: false,
        message: "Please enter policy end date",
      },
      chassis_number: {
        show: false,
        message: "Please enter chassis no",
      },
      nationality: {
        show: false,
        message: "Please select  nationality",
      },
      dob: {
        show: false,
        message: "Please enter date of birth",
      },
      insurance_payment: {
        show: false,
        message: "Please select insurance payment",
      },
      credit_note: {
        show: false,
        message: "Credit note",
      },
      credit_notes_no: {
        show: false,
        message: "Credit note no.",
      },
      debit_note: {
        show: false,
        message: "Please enter the debit & credit notes for the invoice",
      },
      debit_notes_no: {
        show: false,
        message: "Please enter the debit no.",
      },
      endorsement_no: {
        show: false,
        message: "Endorsement no.",
      },
      less_note: {
        show: false,
        message: 'Incorrect Debit Note entered'
      },
      emirates_id: {
        show: false,
        message: "Lebanese ID",
      },

      entity_type: {
        show: false,
        message: "Please select entity type",
      },
      cancellation_credit_amount: {
        required: true,
        message: "Credit note",
        show: false,
      },
      cancellation_credit_notes_no: {
        required: true,
        message: "Please enter Credit note no.",
        show: false,
      },
      cancellation_debit_amount: {
        required: true,
        message: "Please enter Debit note",
        show: false,
      },
      cancellation_debit_notes_no: {
        required: true,
        message: "Please enter Debit note no.",
        show: false,
      },
      cancellation_endorsement_no: {
        required: true,
        message: "Please enter Endorsement no.",
        show: false,
      },
      cancellation_date: {
        required: true,
        message: "Please enter the cancellation date",
        show: false,
      },
      amendment_start_date: {
        required: true,
        message: "Please enter the cancellation date",
        show: false,
      },
      amendment_end_date: {
        required: true,
        message: "Please enter the cancellation date",
        show: false,
      },
      credit_amount_error:{
        show: false,
        message: 'Incorrect amount'
      },
      debit_amount_error:{
        show: false,
        message: 'Incorrect amount'
      },
      cancellation_debit_amount_error:{
        show: false,
        message: 'Incorrect amount'
      },
      
    });
    onMounted(() => {

    //   if(!roles.policiesAssigned.includes(roles.userId())) window.location.href = '/#/404'
        QuotesService.getCountries();
        MasterService.getDocumentTypes({
                categories: 'standard'
            })
      
        MenuComponent.reinitialization();


      // 
        PolicyService.getDebitAmount();
        getPolicyDetails();

      
    });

    const getPolicyDetails = () => {
      const user = JSON.parse(window.localStorage.getItem('user') || '');

      Promise.all([PolicyService.getPolicyDetails(policyBodyParam)]).then(
        (data) => {
          // 
          console.log(store.getters.getpolicyDetails);
          setCurrentPageBreadcrumbs(`Update Data`, ["Policy"]);

          // const customerNameRef:any = inst?.refs?.customerNameRef
          // customerNameRef.focus()
    
        }
      );
    }

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    })

    const debitValue = computed(() => {
      const parentAmount = editPolicyObj.value.car_lead_invoice && editPolicyObj.value.car_lead_invoice.amount ? editPolicyObj.value.car_lead_invoice.amount : 0;
      return parentAmount * (parseInt(store.getters.getDebitValue) / 100);
    });

    const percentage = computed(() => {
      return store.getters.getDebitValue;
    })

 
    const policyDocuments = computed(() => {
      return store.getters.getPolicyDocs;
    });

    const tempDocuments = computed(() => {
      return store.getters.getTempPolicyDocs;
    });
    
    const resetValidation = () => {

      rules.value.policy_start_date.show = false;
      rules.value.policy_end_date.show = false;
      rules.value.nationality.show = false;
      rules.value.dob.show = false;
      rules.value.emirates_id.show = false;
      rules.value.chassis_number.show = false;
      rules.value.cancellation_payable_amount.show = false;
      rules.value.cancellation_credit_amount.show = false;
      rules.value.cancellation_credit_notes_no.show = false;
      rules.value.cancellation_debit_amount.show = false;
      rules.value.cancellation_debit_notes_no.show = false;
      rules.value.cancellation_endorsement_no.show = false;
      rules.value.cancellation_date.show = false;

    }

    const validateForm = (type) => {
     
      if(type == 'policy')
      {
        if (updateDataBodyParam.policy_start_date == "" || updateDataBodyParam.policy_start_date == null) {
          rules.value.policy_start_date.show = true;
          isFormValid.value = false;
        }

        if (updateDataBodyParam.policy_end_date == ""  || updateDataBodyParam.policy_end_date == null) {
          rules.value.policy_end_date.show = true;
          isFormValid.value = false;
        }

        if (updateDataBodyParam.emirates_id == ""  || updateDataBodyParam.emirates_id == null) {
          rules.value.emirates_id.show = true;
          isFormValid.value = false;
        }

        let numberContain = /[0-9]/;;
        let lettersContain = /[A-Za-z]/;
        if (updateDataBodyParam.emirates_id) {
          if(updateDataBodyParam.emirates_id.length > 36) {
            rules.value.emirates_id.message = 'Lebanese ID must not be greater than 36';
            rules.value.emirates_id.show = true;
            isFormValid.value = false;
          } 
          if(updateDataBodyParam.emirates_id.length < 10) {
            rules.value.emirates_id.message = 'Lebanese ID must not be less than 10';
            rules.value.emirates_id.show = true;
            isFormValid.value = false;
          } 
          
          if(!numberContain.test(updateDataBodyParam.emirates_id) || !lettersContain.test(updateDataBodyParam.emirates_id)) {
            rules.value.emirates_id.message = 'Please enter correct Lebanese ID';
            rules.value.emirates_id.show = true;
            isFormValid.value = false;
          }
        }

        if (updateDataBodyParam.chassis_number == ""  || updateDataBodyParam.chassis_number == null) {
          rules.value.chassis_number.show = true;
          isFormValid.value = false;
        }


        if (updateDataBodyParam.nationality == "" || updateDataBodyParam.nationality == null) {
          rules.value.nationality.show = true;
          isFormValid.value = false;
        }

        if (updateDataBodyParam.dob == "" || updateDataBodyParam.dob == null) {
          rules.value.dob.show = true;
          isFormValid.value = false;
        }
      }
      if(type == 'cancellation')
      {

        rules.value.cancellation_payable_amount.message = "Please enter payable amount"
        if (parseFloat(updateDataBodyParam.cancellation_payable_amount) <= 0 &&
      editPolicyObj.value.cancellation_type != "no-reimbursement"
      ) {
        rules.value.cancellation_payable_amount.show = true;
        isFormValid.value = false;
      }

        if(parseFloat(updateDataBodyParam.cancellation_payable_amount) > parseFloat(editPolicyObj.value.car_lead_invoice.sale_price)) {
            rules.value.cancellation_payable_amount.show = true;
            rules.value.cancellation_payable_amount.message =
              "Payable amount cannot be greater than invoice amount.";
            isFormValid.value = false;
        }
        if ((updateDataBodyParam.cancellation_debit_amount == "" || updateDataBodyParam.cancellation_debit_amount == null) && editPolicyObj.value.cancellation_type != "no-reimbursement") {
          rules.value.cancellation_debit_amount.show = true;
          isFormValid.value = false;
        }

        if (updateDataBodyParam.cancellation_debit_amount >= 1000000000) {
          rules.value.cancellation_debit_amount_error.show = true;
          rules.value.cancellation_debit_amount_error.message = 'Incorrect amount';
          isFormValid.value = false;
        }

        //  if (updateDataBodyParam.cancellation_debit_notes_no == "" || updateDataBodyParam.cancellation_debit_notes_no == null) {
        //   rules.value.cancellation_debit_notes_no.show = true;
        //   isFormValid.value = false;
        // }

        if ((updateDataBodyParam.cancellation_credit_amount == "" || updateDataBodyParam.cancellation_credit_amount == null) && editPolicyObj.value.cancellation_type != "no-reimbursement") {
          rules.value.cancellation_credit_amount.show = true;
          isFormValid.value = false;
        }

        // if (updateDataBodyParam.cancellation_credit_notes_no == "" || updateDataBodyParam.cancellation_credit_notes_no == null) {
        //   rules.value.cancellation_credit_notes_no.show = true;
        //   isFormValid.value = false;
        // }

        // if (updateDataBodyParam.cancellation_endorsement_no == "" || updateDataBodyParam.cancellation_endorsement_no == null) {
        //   rules.value.cancellation_credit_notes_no.show = true;
        //   isFormValid.value = false;
        // }

        if (updateDataBodyParam.cancellation_date == "" || updateDataBodyParam.cancellation_date == null) {
          rules.value.cancellation_date.show = true;
          isFormValid.value = false;
        }
      }
      

    }

    const creditIndex = ref<Array<number>>([]);
    const creditIndexNo = ref<Array<number>>([]);
    const debitIndex = ref<Array<number>>([]);
    const debitIndexNo = ref<Array<number>>([]);
    const endorsementNos = ref<Array<number>>([]);
    const isCreditValid = ref(true);
    const isCreditValidNo = ref(true);
    const isDebitValid = ref(true);
    const isDebitValidNo = ref(true);
    const endorsementNo = ref(true);
    const getPolicyStartDate = () => {
       if(editPolicyObj.value.is_amended === 0) {
        setTimeout(() => {
         uploadPolicyBodyParam.policy_start_date = editPolicyObj.value.policy_start_date
          var endDateTime = new Date(
            addMonths(
              new Date(editPolicyObj.value.policy_start_date),
              13
            ).toString()
          );
          let ped = moment(endDateTime).format('YYYY-MM-DD')
          if(ped === 'Invalid date') {
            ped = ''
          }
          if(editPolicyObj.value.status !== 4) {
            editPolicyObj.value.policy_end_date =
            policyEndDate.value =
            uploadPolicyBodyParam.policy_end_date = ped;
          }
          }, 1000);
       }
    };

    function addMonths(date, months) {
      var d = date.getDate();
      date.setMonth(date.getMonth() + +months);
      if (date.getDate() != d) {
        date.setDate(0);
      } 
      date.setDate(date.getDate()-1);
      
      return date;
    }

    

    let showErrors = computed(() => {
      return store.getters.getPolicyErrors;
    });
    let showErrorDetails = computed(() => {
      return store.getters.getPolicyErrorDetails;
    });
    

    const uploadedDocs = computed(() => {
      return store.getters.getPolicyDocs;
    })

    const disablePolicySaveButton = computed(() => {
      return store.getters.getPolicyButtonStatus;
    });
    const disablePolicyDraftSaveButton = computed(() => {
      return store.getters.getPolicyDraftButtonStatus;
    });
    let showView = computed(() => {
      const permissions = store.getters.getUserPermissions;
      return !in_array.value
        ? true
        : false;
    });

    const disabledInput = computed(() => {
      const permissions = store.getters.getUserPermissions;
      return !in_array.value || editPolicyObj.value.status > 4
      // && !(permissions.is_admin || permissions.is_manager || permissions.is_underwritter) 
      ? true : false
    })

    const in_array = computed(() => {
      const data = [4, 1];
        const user = store.getters.currentUser;
        return data.includes(user.role_id);
    })


    const totalAmount = computed(() => {
      return editPolicyObj.value.car_lead_invoices_edit_policy.reduce((total, item) => {
        return total += item.amount
      }, 0)
    })

    const totalDebit = computed(() => {
      return editPolicyObj.value.car_lead_invoices_edit_policy.reduce((total, item) => {
        return total += parseInt(item.debit_amount)
      }, 0)
    })

    

    const loading = ref<boolean>(false);
    const loadingTwo = ref<boolean>(false);
    const loadingThree = ref<boolean>(false);
    



    watchEffect(() => {


      if(editPolicyObj.value && editPolicyObj.value.car_lead?.lead_driver_detail) {
        nationality.value = editPolicyObj.value.car_lead.lead_driver_detail.nationality;
      }

      if(editPolicyObj.value && editPolicyObj.value.car_lead?.lead_driver_detail) {
        dob.value = editPolicyObj.value.car_lead.lead_driver_detail.dob;
      }

    })

    const resetDis = (index) => {
      store.commit(Mutations.SET_IS_DISCREPANCY_ERROR, false);
      console.log(index)
      isDebitValid.value = true; 
      rules.value.less_note.show = false; 
      const cIndex = debitIndex.value.indexOf(index);
      debitIndex.value.splice(cIndex, 1); 
    }

    const resetDistNo = (index) => {
      isDebitValidNo.value = true;
      rules.value.debit_notes_no.show = false;
      const cIndex = debitIndexNo.value.indexOf(index);
      debitIndexNo.value.splice(cIndex, 1); 
    }

    const resetEndorsement = (index) => {
      rules.value.endorsement_no.show = false;
      const cIndex = endorsementNos.value.indexOf(index);
      endorsementNos.value.splice(cIndex, 1); 
    }
    
    const showAction = (data) => {
      const user = store.getters.currentUser;
      return data.includes(user.role_id);
    }

    

    // const customer_name = ref()
    // const emirates_id = ref()
    // const policy_no = ref()
    // const policy_start = ref()
    // const chasiss = ref()
    // const insurance_payment = ref()
    // const entity_type = ref()

    const focusField = () => {
      const { customer_name, emirates_id, policy_no, policy_start_date, chassis, insurance_payment, entity_type, credit_note, credit_notes_no, debit_note, debit_notes_no, endorsement_no, less_note } = rules.value

      if(customer_name.show) {
        const customerNameRef:any = inst?.refs?.customerNameRef
        customerNameRef.focus()
      }

      if(!customer_name.show &&
        emirates_id.show
        ) {
        const emirateIdRef:any = inst?.refs?.emirateIdRef
        emirateIdRef.isFocus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        policy_no.show
        ) {
        const policyNoRef:any = inst?.refs?.policyNoRef
        policyNoRef.focus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        !policy_no.show &&
        policy_start_date.show
        ) {
        const policyStartRef:any = inst?.refs?.policyStartRef
        policyStartRef.isFocus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        !policy_no.show &&
        !policy_start_date.show &&
        chassis.show
        ) {
        const chasissRef:any = inst?.refs?.chasissRef
        chasissRef.focus()
      }

   

      if(!customer_name.show &&
        !emirates_id.show &&
        !policy_no.show &&
        !policy_start_date.show &&
        !chassis.show &&
        !insurance_payment.show &&
        entity_type.show
        ) {
        const enitityTypeRef:any = inst?.refs?.enitityTypeRef
        enitityTypeRef.focus()
      }

      if(!customer_name.show &&
        !emirates_id.show &&
        !policy_no.show &&
        !policy_start_date.show &&
        !chassis.show &&
        !insurance_payment.show &&
        !entity_type.show &&
        editPolicyObj.value.car_lead_invoices_edit_policy.length > 0
        ) {
        if(credit_note.show) {
          const cnRef:any = inst?.refs?.cnRef
          cnRef.forEach(cn => {
            cn.isFocus()
          })
        }
        if(!credit_note.show &&
          credit_notes_no.show
        ) {
          const cnNoRef:any = inst?.refs?.cnNoRef
          cnNoRef.forEach(cnNo => {
            cnNo.focus()
          })
        }
        if(!credit_note.show &&
          !credit_notes_no.show &&
          (less_note.show || debit_note.show)
        ) {
          const dnRef:any = inst?.refs?.dnRef
          dnRef.forEach(dn => {
            dn.isFocus()
          })
        }
        if(!credit_note.show &&
          !credit_notes_no.show &&
          !less_note.show && 
          !debit_note.show &&
          debit_notes_no.show
        ) {
          const dnNoRef:any = inst?.refs?.dnNoRef
          dnNoRef.forEach(dnNo => {
            dnNo.focus()
          })
        }
        if(!credit_note.show &&
          !credit_notes_no.show &&
          !less_note.show &&
          !debit_note.show &&
          !debit_notes_no.show &&
          endorsement_no.show
        ) {
          const endorsementNoRef:any = inst?.refs?.endorsementNoRef
          endorsementNoRef.forEach(endorsement => {
            endorsement.focus()
          })
        }
      }
    }

    const toCustomerName = () => {
      const customerNameRef:any = inst?.refs?.customerNameRef
      customerNameRef.focus()
    }

    const setFocuToChasis = () => {
      const chasissRef:any = inst?.refs?.chasissRef
      chasissRef.focus()
    }

    const toPolicyNo = () => {
      const policyNoRef:any = inst?.refs?.policyNoRef
      policyNoRef.focus()
    }


    const toPolicyStartDate = () => {
      const policyStartRef:any = inst?.refs?.policyStartRef
      policyStartRef.isFocus()
    }
  
    

    const toFirst = () => {
      setTimeout(() => {
        const discrepancyNoRef:any = inst?.refs?.discrepancyNoRef
        discrepancyNoRef.focus()
      }, 500);
    }

  

    const isAmendValid = computed(() => {
      const data = store.getters.getExtensions
      const extensions = data.filter(x => x.original_filename === null)
      return extensions.length > 0 ? false : true
    })



    
  

    const extensionsLogic = computed(() => {
          const data =  store.getters.getExtensions
          return data.length > 0 ? true : false
      })

      const checkPolicyView = computed(() => {
          const data =  store.getters.getPolicyView
          return data;
      })

    let countryList = computed(() => {
      return store.getters.getCountry;
    });

    const currentyear = computed(() => {
      return new Date().getFullYear();
    })
    const currentMonth = computed(() => {
      const month = new Date().getMonth() + 1;
      return month > 9 ? month : '0'+month;
    })
    const currentDay = computed(() => {
      const today = new Date().getDate();
      return today > 9 ? today : '0'+today;
    })

    const dobYear = computed(() => {
      return currentyear.value - 18;
    })
  
    const handlePolicySave = (type) => {
      // alert('handlePolicySave');
      isFormValid.value = true;
      const {policy_start_date, policy_end_date, chassis_number, emirates_id} = editPolicyObj.value;
      updateDataBodyParam.credit_debit_details = [];

      updateDataBodyParam.type = type;
      if(type == 'policy')
      {
        updateDataBodyParam.policy_start_date = policy_start_date;
        updateDataBodyParam.policy_end_date = policy_end_date;
        updateDataBodyParam.emirates_id = emirates_id;
        updateDataBodyParam.chassis_number = chassis_number;
        updateDataBodyParam.nationality = nationality.value;
        updateDataBodyParam.dob = dob.value;
        validateForm(type);
      }

      if(type == 'credit_debit')
      {
        editPolicyObj.value.car_lead_invoices_edit_policy.forEach((invoice) => {

          if(invoice.debit_amount > 9999999999){
            rules.value.debit_amount_error.show = true;
            rules.value.debit_amount_error.message = 'Incorrect amount';
            isFormValid.value = false
          }

          if(invoice.credit_amount > 9999999999){
              rules.value.credit_amount_error.show = true;
              rules.value.credit_amount_error.message = 'Incorrect amount';
              isFormValid.value = false
          }

          let data = {
            invoice_id: invoice.id,
            credit_amount: invoice.credit_amount,
            credit_notes_no: invoice.credit_notes_no,
            debit_amount: invoice.debit_amount,
            debit_notes_no: invoice.debit_notes_no,
            endorsement_no: invoice.endorsement_no,
            amend_type: invoice.amend_type,
            payment_date: invoice.payment_date_without_format,
            reference_no: invoice.reference_no,
            amendment_start_date: invoice.amendment_start_date,
            amendment_end_date: invoice.amendment_end_date,
          };
          updateDataBodyParam.credit_debit_details.push(data);
        });
        
      }
      
      if(type == 'cancellation')
      {
       const {cancellation_debit_amount, cancellation_debit_notes_no,cancellation_credit_amount,cancellation_credit_notes_no,cancellation_endorsement_no,cancellation_payable_amount, cancellation_date} = editPolicyObj.value;
       
        updateDataBodyParam.cancellation_payable_amount = cancellation_payable_amount;
        updateDataBodyParam.cancellation_debit_amount = cancellation_debit_amount;
        updateDataBodyParam.cancellation_debit_notes_no = cancellation_debit_notes_no;
        updateDataBodyParam.cancellation_credit_amount = cancellation_credit_amount;
        updateDataBodyParam.cancellation_credit_notes_no = cancellation_credit_notes_no;

        updateDataBodyParam.cancellation_endorsement_no = cancellation_endorsement_no;
        updateDataBodyParam.cancellation_date = cancellation_date;

         validateForm(type);
      }
      
      if(isFormValid.value)
      {
        if(type == 'policy')
        {
          loading.value = true;
        }
        if(type == 'credit_debit')
        {
          loadingTwo.value = true;
        }
        if(type == 'cancellation')
        {
          loadingThree.value = true;
        }
        
        PolicyService.updateDataPolicy(updateDataBodyParam).then((data) => {
            // data
            loading.value = false;
            loadingTwo.value = false;
            loadingThree.value = false;
            resetValidation()
            getPolicyDetails();

        }).catch(() => {
            loading.value = false;
            loadingTwo.value = false;
            loadingThree.value = false;
        });
        
      }
      

    }
    
    const payableAmount = () => {
      if(editPolicyObj.value.cancellation_type === 'partial-cancellation') {
        editPolicyObj.value.cancellation_payable_amount = roundUp(editPolicyObj.value.cancellation_credit_amount - editPolicyObj.value.cancellation_debit_amount);
      } else {
        editPolicyObj.value.cancellation_payable_amount = roundUp(editPolicyObj.value.cancellation_credit_amount)
      }
    }

    const roundUp = (num: number) => {
        var p = Math.pow(10, 2);
        return Math.round(num * p) / p;
    }
    return {
      loading,
      loadingTwo,
      loadingThree,
      roundUp,
      payableAmount,
      handlePolicySave,
      nationality,
      dob,
      dobYear,
      currentMonth,
      countryList,
      extensionsLogic,
      toCustomerName,
      isAmendValid,
      toFirst,
      toPolicyStartDate,
      focusField,
      toPolicyNo,
      // entity_type,
      // insurance_payment,
      // chasiss,
      // policy_start,
      // policy_no,
      // customer_name,
      // emirates_id,
      roles,
      getPolicyDetails,
      showAction,
      resetEndorsement,
      resetDistNo,
      isCreditValidNo,
      endorsementNo,
      isDebitValidNo,
      creditIndexNo,
      debitIndexNo,
      endorsementNos,
      setFocuToChasis,
      resetDis,
      tempDocs,
      MasterService,
      percentage,
  
      creditIndex,
      debitIndex,
      isCreditValid,
      isDebitValid,
      ElNotification,
      in_array,
      debitValue,
      totalDebit,
      totalAmount,
      uploadedDocs,
      disabledInput,
      resetValidation,
      showErrorDetails,
      uploadPolicyBodyParam,
      policyEndDate,
      getPolicyStartDate,
      showErrors,
      editPolicyObj,
      customerId,
      leadId,
      rules,
      showView,
      tempDocuments,
      policyDocuments,
      disablePolicySaveButton,
      disablePolicyDraftSaveButton,
      checkPolicyView,
      currentDay,
    };
  },
});

